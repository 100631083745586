/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base";
@import "app/theme/styles/admin";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import '../node_modules/font-awesome/css/font-awesome.css';
@import "app/theme/styles/rtl";
@import '../node_modules/ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS



:root {
  --primary-color: #fcba03;
  --primary-mat-color: #cead41;
  --secondary-color: #242424;
  --text-color: #000000;
  --my-primary-gradient: rgb(252,186,3);
  --my-primary-gradient: -moz-linear-gradient(27deg, rgba(252,186,3,1) 0%, rgba(255,239,2,1) 47%, rgba(252,186,3,1) 99%);
  --my-primary-gradient: -webkit-linear-gradient(27deg, rgba(252,186,3,1) 0%, rgba(255,239,2,1) 47%, rgba(252,186,3,1) 99%);
  --my-primary-gradient: linear-gradient(27deg, rgba(252,186,3,1) 0%, rgba(255,239,2,1) 47%, rgba(252,186,3,1) 99%);
  --my-primary-gradient-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcba03",endColorstr="#fcba03",GradientType=1);
}


.app.grey .mat-toolbar.mat-primary{
  background:black !important;
  color:var(--text-color) !important;
}
@media screen and (max-width: 600px) {



  .mat-dialog-container{
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .cdk-overlay-pane{
    min-width: 100vw !important;
    width: 100vw !important;
  }


}
.app.grey .top-toolbar.mat-toolbar-row {
  color:var(--text-color) !important;
}
.app.grey .top-toolbar.mat-toolbar-row .top-menu a {
  color: var(--text-color);
}
.app.grey .bg-primary{
  background:black;
  color:var(--text-color);
}
.app img {
  margin: 0px;
}
